body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    to left,
    red 0%,
    orange 50%,
    yellow 100%
  );
  text-align: center;
}

.app-title{
  margin-top: 75px;
  margin-bottom: 50px;
  font-size: 76px;
  color:lightgoldenrodyellow;
  font-family: 'Bigelow Rules';
}
